import React, { useEffect, useState, RefObject, useRef } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { AnimatePopup, ParagraphText } from "@Styled";
import { ISinglePlayerGameBoard } from "./ISinglePlayerGameBoard";
import { WarningModal } from "@Components/Modals/WarningModal";
import { useTranslation } from "react-i18next";
import { RotateScreenModal } from "@Components/Modals/RotateScreenModal";

import { HomeActions } from "@Actions/Home";
import { setViewPortSize } from "@HelperFunctions";

export const SinglePlayerGameBoard: React.FunctionComponent<
  ISinglePlayerGameBoard.IProps
> = ({ show, selectedGame, selectedLanguage, selectedGameId, exitGame }) => {
  const { t } = useTranslation();

  const [startGame, setStartGame] = useState(false);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const [iframeContentString, setIframeContentString] = useState<any>("");
  const [rotationMessage, setRotationMessage] = useState("");
  const [showDeviceRotate, setShowDeviceRotate] = useState(false);
  const [orientation, setOrientation] = useState("portrait");

  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const iframeRef: RefObject<HTMLIFrameElement> = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (document.getElementById("smartbanner")) {
      (window as any).bannerHide();
    }
  }, []);

  useEffect(() => {
    let orientationEventName = (window as any).getOrientationEventName();
    console.log("event name: ", orientationEventName);
    manageRotation();
    const handleOrientationChange = (event: any) => {
      manageRotation();
    };
    window.addEventListener(orientationEventName, handleOrientationChange);

    return () => {
      window.removeEventListener(orientationEventName, handleOrientationChange);
    };
  }, [iframeContentString]);

  useEffect(() => {
    if (selectedGame.gameId >= 0) {
      updateFrameDocument();
    }
    // })();
  }, [selectedGame.gameId]);

  useEffect(() => {
    if (selectedGame.orientation === "landscape") {
      let vpc = document.getElementById("view-port-container");
      if (vpc) {
        vpc.style.width = window.innerWidth.toString() + "px";
        vpc.style.maxWidth = window.innerWidth.toString() + "px";
      }
    }
    return () => {
      if (selectedGame.orientation === "landscape") {
        let vpc = document.getElementById("view-port-container");
        if (vpc) {
          vpc.style.width = "600px";
          vpc.style.maxWidth = "600px";
        }
      }
    };
  }, []);

  const updateFrameDocument = async () => {
    const gameDocumentStr: any = await dispatch(
      HomeActions.GetGameDocStr({
        game: selectedGame,
      })
    );

    if (typeof gameDocumentStr === "string") {
      const scriptTagUpdatedDoc = updateScriptTags(gameDocumentStr);
      const docStr =
        "<!DOCTYPE HTML><html>" +
        scriptTagUpdatedDoc.documentElement.innerHTML +
        "</html>";
      setIframeContentString(docStr);
    }
  };

  const updateScriptTags = (gameDocumentStr: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(gameDocumentStr, "text/html");
    const scripts = doc.getElementsByTagName("script");
    const gameUrl = selectedGame.url;

    for (let i = 0; i < scripts.length; i++) {
      if (
        scripts[i].src === "https://cdn.socket.io/socket.io-2.3.0.js" ||
        scripts[i].src ===
          "https://www.googletagmanager.com/gtag/js?id=G-WMR1V3CTJ4" ||
        scripts[i].src ===
          "https://www.googletagmanager.com/gtag/js?id=G-HRKWQ8TPMF"
      ) {
        continue;
      }

      if (scripts[i].src) {
        const pathArr = (scripts[i].attributes as any).src.value.split("/");
        let pathStr = gameUrl + "/";
        for (let i = 1; i < pathArr.length; i++) {
          pathStr += pathArr[i] + (i < pathArr.length - 1 ? "/" : "");
        }
        scripts[i].src = pathStr;
      } else {
        console.log("Couldn't load inner html : ", scripts[i].innerHTML);
      }
    }
    const script = doc.createElement("script");
    script.innerHTML =
      "(function() {" +
      "window.STATIC_PATH =" +
      `"${gameUrl}` +
      '";' +
      "window.sender = function(data) {" +
      "window.parent.postMessage(data,window.origin);" +
      "};" +
      "})()";
    doc.head.appendChild(script);
    return doc;
  };

  const onIFrameLoad = () => {
    console.log("selected game    :", selectedGame);
    const iframeWindow = (iframeRef.current! as any).contentWindow;
    iframeWindow.language = selectedLanguage.toUpperCase();
    iframeWindow.gameID = selectedGameId;
    iframeWindow.productType = "GAGO";
    iframeWindow.environment =
      process.env.REACT_APP_IS_PRODUCTION_API === "true"
        ? "production"
        : "staging";
  };

  const onIFrameLoadError = (e: any) => {
    console.log("onIFrameLoadError : ", e);
  };

  const manageRotation = () => {
    // debugger;
    const game = selectedGame;

    setRotationMessage(
      game.orientation === "landscape"
        ? "ROTATE_DEVICE_LANDSCAPE"
        : "ROTATE_DEVICE_PORTRAIT"
    );
    let orientationData = !navigator.maxTouchPoints
      ? "desktop"
      : !window.orientation
      ? "portrait"
      : "landscape";
    setOrientation(orientationData);
    if (orientationData === "desktop") {
      setStartGame(true);
      return;
    }

    if (game.orientation === "landscape") {
      (window as any).openFullScreen("view-port-container");
    }

    if (startGame) {
      setShowDeviceRotate(false);
      setViewPortSize(true, orientationData);
      return;
    }

    if (orientationData != game.orientation) {
      console.log("useeffect orientation: ", orientationData);
      setViewPortSize(true, orientationData);
      setShowDeviceRotate(true);
    } else {
      if (orientationData === "landscape" && game.orientation === "landscape") {
        setShowDeviceRotate(false);
        setStartGame(true);
        setViewPortSize(true, orientationData);
      } else {
        setShowDeviceRotate(false);
        setStartGame(true);
        setViewPortSize(false, orientationData);
      }
    }
  };

  return show
    ? ReactDOM.createPortal(
        <AnimatePopup animateIn={true}>
          {showCancelWarning && (
            <WarningModal
              onYesClick={() => {
                exitGame();
              }}
              onNoClick={() => {
                setShowCancelWarning(false);
              }}
              warningTextElem={
                <>
                  <div className="text-white text-center font-20px titillium-semibold">
                    Quit?
                  </div>
                  <ParagraphText className="text-white text-center font-16px">
                    {t("SP_Game_Quit_Msg")}
                  </ParagraphText>
                </>
              }
              width={
                selectedGame.orientation === "landscape" && !isMobileDevice
                  ? "40%"
                  : ""
              }
              backgroundColor="#430676"
            />
          )}

          <div
            className="position-fixed"
            style={{ top: "5px", left: "5px" }}
            onClick={() => {
              setShowCancelWarning(true);
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                background: "rgb(0,0,0,0.7)",
                borderRadius: "50%",
                width: "35px",
                height: "35px",
              }}
            >
              <img className="mr-1" height="14px" src="/images/back.png" />
            </div>
          </div>
          {startGame && (
            <iframe
              srcDoc={iframeContentString}
              allow=""
              height="100%"
              width="100%"
              loading="eager"
              name={selectedGame.title}
              style={{ border: "none" }}
              onLoad={() => {
                onIFrameLoad();
              }}
              onError={(e) => {
                onIFrameLoadError(e);
              }}
              id="iFrame"
              ref={iframeRef}
            ></iframe>
          )}
          {showDeviceRotate && <RotateScreenModal text={rotationMessage} />}
        </AnimatePopup>,
        document.getElementById("view-port-container")!
      )
    : null;
};
