import React, { useState, useEffect } from "react";

import { GAME_ITEM } from "@Interfaces";
import { IGameItem } from "./IGameItem";
import styles from "../../Containers/Home/style.module.scss";
import { Link } from "react-router-dom";
import { LazyImage, ShimmerContainer, GradientLoadingRing } from "@Styled";
import { InviteStatusEnums, InviteActionsEnum } from "@Constants";
import { useTranslation } from "react-i18next";
import { getServerTimeOffset } from "@Services/FirebaseEvents";
export const GameItemWithoutBackground: React.FunctionComponent<
  IGameItem.IProps
> = ({
  game,
  noOfItemsPerRow,
  onGameClick,
  selectedLanguage,
  gameCategoryDirectionVertical,
  itemSpace,
  index,
}) => {
  const [numOfPlayers, setNumOfPlayers] = useState(
    Math.floor(Math.random() * 1500) + 500
  );
  const [itemWidth, setItemWidth] = useState(
    noOfItemsPerRow && 95 / noOfItemsPerRow
  );
  const { t } = useTranslation();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setNumOfPlayers(Math.floor(Math.random() * 1500) + 500);
  //   }, Math.random() * 10000 + 5000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const getMargin = (): string => {
    let margin = "";
    if (index && noOfItemsPerRow && itemSpace) {
      if (index % noOfItemsPerRow === 0) {
        margin = "0";
      } else margin = "" + itemSpace / (noOfItemsPerRow - 1);
    }
    return margin;
  };

  return (
    // width: 25%;
    // margin: 1rem 1.5rem;
    <div
      style={{
        position: "relative",
        marginRight: gameCategoryDirectionVertical
          ? `${selectedLanguage === "en" && getMargin()}%`
          : "",
        // marginRight: `${(index && noOfItemsPerRow) &&((index + 1) % noOfItemsPerRow === 0) ? '0' : (noOfItemsPerRow && itemSpace && selectedLanguage === 'en') && (itemSpace / noOfItemsPerRow)}%`,
        marginLeft: gameCategoryDirectionVertical
          ? `${selectedLanguage === "ar" && getMargin()}%`
          : "",
        // width: gameCategoryDirectionVertical && noOfItemsPerRow  ? `${93/noOfItemsPerRow}%` : ""
        width:
          gameCategoryDirectionVertical && noOfItemsPerRow
            ? `${92 / noOfItemsPerRow}%`
            : "130px",
      }}
      onClick={() => {
        onGameClick(game);
      }}
      className={
        "cursor-pointer align-items-stretch " +
        (gameCategoryDirectionVertical
          ? styles.singlePlayerGamesDisplay
          : styles.flexGameItems)
      }
    >
      {/*{game.premiumTagAr && (*/}
      {/*  <img*/}
      {/*  style={{left:'62%'}}*/}
      {/*    className="premium-game"*/}
      {/*    src={`${*/}
      {/*      selectedLanguage === "ar" ? game.premiumTagAr : game.premiumTagEn*/}
      {/*    }`}*/}
      {/*  />*/}
      {/*)}*/}
      <LazyImage
        src={
          game.thumbnail
            ? game.thumbnail
            : game.gifUrl && game.gifUrl !== "false"
            ? game.gifUrl
            : ""
        }
        alt={game.title}
        height="100%"
        borderRadius="5px"
        width="100%"
      />
      <div className="text-white">
        <div
          className="mt-2 font-weight-bold"
          style={{
            top: "80%",
            left: 0,
            right: 0,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {game.title}
        </div>
        {/*<div className="font-12px">{numOfPlayers} {t("PLAYERS")}</div>*/}
      </div>
    </div>
  );
};
